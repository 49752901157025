import * as React from 'react'

import { SEO } from '../../components/seo'
import { NewsletterInput } from '../../components/newsletter'

type Props = {}

export default class StyleguidePage extends React.Component<Props, {}> {
  public render() {
    return (
      <>
        <SEO title="Styleguide" />
        <header className="container container--grid">
          <div className="container__row">
            <h1 className="type-desktop-h1">Form fields</h1>
          </div>
        </header>
        <section className="container container--grid">
          <div className="container__row">
            <h2 className="type-desktop-nav-menu-lg">NewsletterInput</h2>

            <h3 className="type-desktop-h3">Default</h3>
            <NewsletterInput
              inputId="styleguide-newsletter-cta-default"
              label="Caution: These work"
              placeholder="hello@danny.codes"
            />

            <h3 className="type-desktop-h3">"huge" variant</h3>
            <NewsletterInput
              appearance="huge"
              inputId="styleguide-newsletter-cta-huge"
              label="Caution: These work"
              placeholder="hello@danny.codes"
            />
          </div>
        </section>
      </>
    )
  }
}
